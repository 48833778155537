<template>
    <chart-panel border class="pa-1 chart-container">
        <fe-spinner v-if="isLoading" :style="{height: localHeight}"/>

        <template v-if="showChart" #tools>
            <div class="d-block">
                <div class="d-flex justify-end">
                    <fe-info-tip
                        v-if="targetSetWarning"
                        class="mr-1"
                        style="transform: translate(0, 8px)"
                        :tooltip="targetSetWarning"
                    />

                    <fe-icon-btn
                        v-if="pinnable"
                        useIcon="far fa-thumbtack"
                        usage="ghost"
                        small
                        @click="pinDialog.show=true; pinDialog.title=title"
                    />

                    <fe-icon-btn
                        v-if="!expanded"
                        useIcon="fas fa-expand-alt"
                        usage="ghost"
                        small
                        @click="doExpand"
                    />

                    <fe-icon-btn
                        v-if="collectionChart"
                        useIcon="far fa-trash"
                        usage="ghost"
                        small
                        @click="$emit('delete')"
                    />

                    <div style="padding-top: 6px;">
                        <menu-btn ref="menuBtn">
                            <fe-tooltip
                                style="width: 100%"
                                :disabled="canToggleBetweenViewModes"
                                :tooltip="`This chart does not have data for ${localType === 'basic' ? 'Performance Levels' : 'Benchmark Levels'}.  Your Performance Bands may not be correctly configured.`"
                            >
                                <v-list-item
                                    v-if="!projections"
                                    :disabled="!canToggleBetweenViewModes"
                                    @click="doChartDetail"
                                >
                                    <i class="far fa-search-plus fe-icon search-icon"></i>

                                    <span v-if="localType === 'basic'" class="ml-6">View Performance Levels</span>

                                    <span v-else class="ml-6">View Benchmark Levels</span>
                                </v-list-item>
                            </fe-tooltip>

                            <v-list-item @click="viewBy(!stackingType?'normal' : undefined)">
                                <i class="far fa-chart-bar fe-icon chart-icon"></i>
                                <span class="ml-6">Change Chart Type</span>
                            </v-list-item>

                            <v-list-item @click="enableTrendline" v-if="hideLines">
                                <i class="far fa-analytics fe-icon"></i>
                                <span class="ml-6">{{ !showTrendline ? 'Enable' : 'Disable' }} Trend Line</span>
                            </v-list-item>

                            <v-list-item @click="doShowLabels">
                                <i class="far fa-hashtag fe-icon hashtag-icon"></i>
                                <span class="ml-6">{{ showLabels ? 'Hide' : 'Display' }} Numbers on Chart</span>
                            </v-list-item>

                            <v-list-item @click="viewBy('percent')" v-if="stackingType!='percent'">
                                <i class="far fa-percent fe-icon percent-icon"></i>
                                <span class="ml-6">Change to % of Students</span>
                            </v-list-item>

                            <v-list-item @click="viewBy('stacking')" v-if="stackingType!='stacking'">
                                <i class="far fa-hashtag fe-icon hashtag-icon"></i>
                                <span class="ml-6">Change to # of Students</span>
                            </v-list-item>

                            <v-list-item @click="doLegend">
                                <i class="far fa-minus-circle fe-icon minus-icon"></i>
                                <span class="ml-6">{{ showLegend ? 'Hide' : 'Show' }} Legend</span>
                            </v-list-item>

                            <v-list-item @click="print">
                                <i class="far fa-print fe-icon print-icon"></i>
                                <span class="ml-6">Print Chart</span>
                            </v-list-item>

                            <v-list-item @click="exportCSV">
                                <i class="far fa-table fe-icon print-icon"></i>
                                <span class="ml-6">Export as CSV</span>
                            </v-list-item>

                            <v-list-item @click="doUpdate" v-if="collectionChart">
                                <i class="far fa-cog fe-icon print-icon"></i>
                                <span class="ml-6">Update Pin</span>
                            </v-list-item>

                            <v-list-item @click="doRename" v-if="collectionChart">
                                <i class="far fa-edit fe-icon print-icon"></i>
                                <span class="ml-6">Rename Pin</span>
                            </v-list-item>

                            <fe-tooltip
                                v-if="sloEnabled && sloCreateButtonVisible"
                                tooltip="SLO cannot be created in multi-year searches"
                                style="width: 100%"
                                :disabled="sloCreateButtonEnabled"
                            >
                                <v-list-item v-if="hideSlo" :disabled="!sloCreateButtonEnabled" @click="createSLO">
                                    <i class="far fa-edit fe-icon print-icon"></i>
                                    <span class="ml-6">Create SLO</span>
                                </v-list-item>

                                <v-list-item
                                    v-else-if="!hideSlo"
                                    :disabled="!sloCreateButtonEnabled"
                                    @click="cancelSLO"
                                >
                                    <i class="far fa-ban fe-icon print-icon"></i>
                                    <span class="ml-6">Cancel SLO</span>
                                </v-list-item>
                            </fe-tooltip>

                            <v-menu v-if="showSort" offset-x left>
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on">
                                        <i class="far fa-sort-amount-down fe-icon" :style="{fontSize: '14px'}"/>
                                        <span class="ml-6">Sort</span>
                                    </v-list-item>
                                </template>

                                <v-list>
                                    <v-list-item v-for="(sort, i) in sortItems" :key="i" @click="doSort(sort, true)">
                                        <span class="ml-6">{{ capitalize(sort) }}</span>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </menu-btn>
                    </div>
                </div>

                <div
                    v-if="!projections && filteredPerformanceBands && showChart"
                    class="d-flex justify-end pt-1 pr-2"
                    style="max-width: 193px;"
                >
                    <fe-remote-combo
                        v-model="selectedTargetSet"
                        style="width: 185px;"
                        :placeholder="filteredPerformanceBands.length > 0 ? 'Performance Band' : 'No performance bands'"
                        :items="filteredPerformanceBands"
                        :disabled="filteredPerformanceBands.length === 0"
                        dense
                        lazyLoad
                        hide-details
                    />
                </div>
            </div>
        </template>

        <div class="d-flex" style="width: 100%; height: 88px;">
            <div class="d-flex chart-title-container" style="width: 100%;">
                <v-icon class="align-self-baseline pt-1" :color="thumbnail.color">{{ thumbnail.icon }}</v-icon>
                <fe-tooltip :disabled="tooltipDisable" :tooltip="getTitle" style="width: 100%">
                    <div ref="title" class="ma-2 chart-title" v-html="getTitle"></div>
                </fe-tooltip>
            </div>
        </div>

        <div class="d-flex mb-2" style="border-bottom: 1px solid rgb(224, 225, 230);"/>

        <fe-tooltip
            tooltip="Click on any part of the chart to establish a baseline for your SLO based on that area."
            direction="bottom"
            :value="!hideSlo"
            caret
            block
            persistent
        >
            <highcharts
                v-if="showChart"
                v-show="ready"
                ref='chartRef'
                style="overflow: hidden"
                :options="localConfig"
                :key="`chart-`+localType"
            />

            <div class="d-flex justify-center" style="flex-wrap: wrap">
                <template v-for="filter in subtitleFilters">
                    <fe-chip>{{ filter }}</fe-chip>
                </template>

                <span
                    v-if="moreFilters"
                    class="show-more-filters-btn"
                    @click="showMoreFilters = !showMoreFilters"
                >
                    <u>{{ showMoreFilters ? 'Show More' : 'Show Less' }}</u>
                </span>
            </div>
        </fe-tooltip>

        <div
            v-if="!showChart && !isLoading"
            class="d-flex flex-fill flex-column justify-center text-center"
            style="height: 300px;"
        >
            <v-img
                src="../../assets/no-data.png"
                max-width="80"
                max-height="80"
                style="opacity: .25; margin-left: auto; margin-right: auto" class="align-center"
            />

            <span style="font-size: 16px; opacity: .25;">No data to display</span>
        </div>

        <pin-dialog v-if="pinDialog.show" v-model="pinDialog.show" :pinParams="pinParams" :title="pinDialog.title"/>

        <fe-dialog
            v-if="showModifyDialog"
            title="Modify Saved Search"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            width="500"
            persistent
            @accept="showModifyDialog = false; modifySavedSearch()"
            @dismiss="showModifyDialog = false"
        >
            <v-layout row wrap>
                <div v-if="modifyFields.includes('SCHOOL_YEAR_ID')" class="d-flex align-center mb-2">
                    <span style="width: 100px">School Year: </span>
                    <fe-remote-exclude-combo
                        v-model="selected.SCHOOL_YEAR_ID"
                        rootProperty="years"
                        itemText="name"
                        style="width: 290px"
                        :url="$models.getUrl('schoolYear', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>

                <div v-if="modifyFields.includes('SCHOOL_ID')" class="d-flex align-center mb-2">
                    <span style="width: 100px">School: </span>

                    <fe-remote-exclude-combo
                        v-model="selected.SCHOOL_ID"
                        rootProperty="schools"
                        style="width: 290px"
                        :url="$models.getUrl('school', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>

                <div v-if="modifyFields.includes('GRADE_ID')" class="d-flex align-center mb-2">
                    <span style="width: 100px">Grade: </span>

                    <fe-remote-exclude-combo
                        v-model="selected.GRADE_ID"
                        rootProperty="grades"
                        style="width: 290px"
                        :url="$models.getUrl('grade', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>

                <div v-if="modifyFields.includes('COHORT_SCHOOL_YEAR_ID')" class="d-flex align-center mb-2">
                    <span style="width: 100px">Cohort School Year: </span>
                    <fe-remote-exclude-combo
                        v-model="selected.COHORT_SCHOOL_YEAR_ID"
                        rootProperty="years"
                        style="width: 290px"
                        :url="$models.getUrl('schoolYear', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>

                <div v-if="modifyFields.includes('GENDER')" class="d-flex align-center mb-2">
                    <span style="width: 100px">Gender: </span>
                    <fe-remote-exclude-combo
                        v-model="selected.GENDER"
                        rootProperty="genders"
                        url="filters.php?action=get&filter=gender"
                        itemText="gender"
                        itemValue="gender"
                        style="width: 290px"
                        hide-details
                        lazyLoad
                    />
                </div>
            </v-layout>
        </fe-dialog>

        <fe-dialog
            v-if="showRenameDialog"
            title="Rename Chart"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            persistent
            @accept="showRenameDialog = false; renamePin()"
            @dismiss="showRenameDialog = false"
        >
            <v-layout row>
                <div class="d-flex align-center">
                    <span>Rename:</span>
                    <fe-text-field v-model="newPinName" class="ml-2"/>
                </div>
            </v-layout>
        </fe-dialog>

        <fe-dialog
            v-if="showSLOCreationYear"
            title="Confirm SLO Creation Year"
            body="You are about to create an SLO with data from a past year. Are you sure you want to continue?"
            acceptButtonText="Continue"
            dismissButtonText="Cancel"
            persistent
            @accept="showSLOCreationYear = false; hideSlo = !hideSlo"
            @dismiss="showSLOCreationYear = false"
        />
    </chart-panel>
</template>

<script>
import {mapState} from 'vuex'
import Highcharts from 'highcharts'
import {Chart} from 'highcharts-vue'
import MenuTools from './MenuTools'
import MenuBtn from '@/components/common/button/MenuBtn'
import PinDialog from '@/components/common/PinDialog'
import ChartPanel from '@/components/charts/ChartPanel'
import windowOptionsMixin from '@/mixins/windowOptions'
import SloCreationCharting from '@/components/modules/slo/creation/charting/Index'

export default {
    name: 'BenchmarkChart',

    components: {
        Chart,
        MenuTools,
        MenuBtn,
        PinDialog,
        ChartPanel,
        SloCreationCharting,
    },

    mixins: [windowOptionsMixin],

    props: {
        config: {
            required: true,
            type: Object
        },
        type: {
            required: true,
            type: String,
            default: 'basic'
        },
        credits: {
            enabled: false
        },
        height: {
            default: '300px'
        },
        border: {
            type: Boolean,
            default: true
        },
        defaultStackType: {
            type: String,
            default: 'stacked'
        },
        uiConfig: {
            default: () => {
                return {}
            }
        },
        collectionChart: {
            type: Boolean,
            default: false
        },
        pinTitle: {
            type: String,
            default: null
        },
        showSort: {
            type: Boolean,
            default: true
        },
        pinnable: {
            type: Boolean,
            default: true
        },
        projections: {
            type: Boolean,
            default: false
        },
        performanceBands: {
            type: Array,
            default: () => []
        },
        defaultPerformanceBand: {
            type: Object,
            required: false
        },
        params: {
            type: Object,
            default: () => {}
        },
        scope: {
            type: String,
            default: null
        },
        expanded: {
            type: Boolean,
            default: false
        },
        advancedFilters: {
            type: Array,
            default: () => []
        },
        suppressDefaultDrilldown: {
            type: Boolean,
            default: false
        },
        renameFill: {
            type: String,
            default: null
        },
        isProgramEval: {
            type: Boolean,
            default: null
        }
    },

    data() {
        return {
            ready: false,
            pinDialog: {
                show: false,
                title: '',
            },
            seriesConfig: {},
            localConfig: {},
            chartRecord: [],
            overallRecord: {},
            baseParams: {},
            fields: [],
            modifyFields: [],
            searchArguments: [],
            selected: {},
            showModifyDialog: false,
            showRenameDialog: false,
            newPinName: null,
            colors: [],
            sortItems: [],
            chartTitle: '',
            showExpandDialog: false,
            thumbnail: {
                icon: 'bar_chart',
                color: '#5fa2dd'
            },
            localHeight: null,
            title: '',
            selectedTargetSet: this.config?.target_set_id ? {id: this.config.target_set_id} : 0,
            currentTargetSet: null,
            stackingType: 'stacking',
            localType: null,
            showLabels: false,
            showLegend: true,
            trendline: {},
            isLoading: false,
            updatingChart: false,
            hideSlo: true,
            showSLOCreationYear: false,
            clickParams: {},
            clickFound: {},
            tooltipDisable: true,
            sloDrilldown: {},
            showMoreFilters: true,
            advancedDemFilters: '',
            moreFilters: false,
            previousPoint: null,
            // Each time we parse server response, we'll check to see if we have any
            // data available for basic-vs-detailed views.  If we don't have both, then
            // for example we'll disable toggling between the 2 views
            hasBasicChartData: true,
            hasDetailedChartData: true,
            showTrendline: false,
            renameHold: null,
            localIsProgramEval: null
        }
    },

    computed: {
        ...mapState('global', ['currentYear']),

        filteredPerformanceBands() {
            let results = []

            let d = this.performanceBands?.filter(t => t.id === this.overallRecord.student_params?.data_point_type_id)
            if (d?.length) {
                let paramYears = [].concat(this.overallRecord.student_params.school_year_id || [])
                let source = []
                d[0]?.schoolYears.forEach(year => {
                    if (!year.id || paramYears.find(itm => parseFloat(itm) === year.id)) source = source.concat(year.targetSets)
                })
                results = source
            }

            return results
        },

        canToggleBetweenViewModes() {
            return (this.hasBasicChartData && this.hasDetailedChartData)
        },

        // In rarer cases, the backend may detect uneven results whereby some
        // scores were only matched successfully against a benchmark range, or a
        // performance range.  In these cases it will supply a warning message that
        // we can display in an info tip.
        targetSetWarning() {
            let warnings
            if (this.localType === 'basic') {
                warnings = this.overallRecord.BASIC?.map(itm => itm.target_set_warning).filter(itm => !!itm)
            } else {
                warnings = this.overallRecord.DETAILED?.map(itm => itm.target_set_warning).filter(itm => !!itm)
            }

            // If we find more than one warning, just return the first.  (They're probably all the same anyway.)
            return warnings?.[0] || ''
        },

        hideLines() {
            return !!this.$store.state.global.sessionUser.district.hide_score_chart_lines
        },

        showChart() {
            return this.updatingChart || this.chartRecord.length === 0 || this.localConfig.series.length === 0 ? false : true
        },

        getTitle() {
            return this.pinTitle ? this.pinTitle : this.title
        },

        isMultipleSchoolYearsSelected() {
            if (this.params?.school_year_id instanceof Array) {
                return (this.params?.school_year_id?.length > 1)
            } else {
                return false // it's either a single int, it's not defined, or params wasn't supplied
            }
        },

        pinParams() {
            let p = {dashboard_saved_search_type_id: 1}
            p.target_set_id = this.overallRecord.target_set_id
            Object.assign(p, this.overallRecord.student_params)

            if (!p.cohort_school_year_id && this.params.cohort_school_year_id) {
                p.cohort_school_year_id = this.params.cohort_school_year_id
            }

            // get the ui config based on user changes
            p.config_text = {
                chart: {}
            }
            let c = p.config_text.chart

            if (this.stackingType === undefined) {
                c.set_stacked = false
            } else if (this.stackingType === 'percent') {
                c.one_hundred_pct_height = 1
            } else if (this.stackingType === 'normal') {
                c.set_stacked = true
            }

            c.isProgramEval = this.localIsProgramEval

            if (this.showLabels) c.hide_label = false
            if (!this.showLegend) c.hide_legend = true
            // active_item carried over from old ui.  0 = basic, 1 = detailed
            if (this.localType === 'detailed') c.active_item = 1;

            return p
        },

        sloEnabled() {
            return this.$store.getters['flags/flags']['ec-slo'] === true
        },

        sloCreateButtonVisible() {
            return (this.$route.name === 'Report' || this.params?.isEmbeddedInDockableWindow)
        },

        sloCreateButtonEnabled() {
            // If accessed from various analytics/charting searches, check the given
            // props to make a similar determination
            if (this.params?.isEmbeddedInDockableWindow) {
                return (this.scope?.toLowerCase() === 'district' || !this.isMultipleSchoolYearsSelected)
            }

            // If accessed from traditional District/Grade/class search, only allow creation button
            // if a single data year is requested.  (Note:  District searches are always a single data year, currently.)
            else if (this.$route.name === 'Report') {
                return (this.$route.params?.scope === 'District' || !this.isMultipleSchoolYearsSelected)
            }

            return false
        },

        subtitleFilters() {
            let filters = ''
            if (this.overallRecord && this.overallRecord.filters_applied) {
                filters += this.overallRecord.filters_applied
            }
            if (this.advancedDemFilters) {
                filters += this.advancedDemFilters
            }
            if (filters.length) {
                let filterArr = filters.split('|').filter(f => f !== '')
                this.moreFilters = filterArr.length > 3
                return this.showMoreFilters && this.moreFilters ? filterArr.slice(0, 3) : filterArr
            }
        },
    },

    watch: {
        deep: true,

        config() {
            this.parseConfig()
        },

        selectedTargetSet(v) {
            if (v) {
                this.requeryData()
                this.resizeCharts()
                this.checkForUnknownBand(v)
            }
        }
    },

    created() {
        window.addEventListener('keydown', this.onKeyDown)
    },

    mounted() {
        if (this.height === 'fullscreen') {
            this.localHeight = (window.innerHeight - 240) + 'px'
        } else {
            this.localHeight = this.height
        }

        this.localType = this.type
        this.stackingType = this.defaultStackType

        let c = this.uiConfig?.chart
        if (c) {
            // set_stacked can be 0/1 or true/false, if not defined default to stacking
            this.stackingType = (c.set_stacked === false || c.set_stacked === 0) ? undefined : 'stacking'
            if (c.one_hundred_pct_height) this.stackingType = 'percent'

            this.showLabels = c.hide_label === false
            this.showLegend = c.hide_legend === false || true
            // active_item carried over from old ui.  0 = basic, 1 = detailed
            if (c.active_item) this.localType = 'detailed'
            if (c.isProgramEval) this.localIsProgramEval = c.isProgramEval // use saved value from config_text
            if (c.one_hundred_pct_height === 1) this.stackingType = 'percent'
        }

        if (this.isProgramEval) this.localIsProgramEval = this.isProgramEval // if expanded window, use prop value

        this.parseConfig()

        /** This timeout is to force highcharts to calculate total and pct values for un-stacked charts **/
        setTimeout(() => {
            this.setStackingPrefs()
        }, 100)

        this.disableTooltip()

        // If user expands into enlarged mode and had previously selected an
        // alternate performance band, "retain" that selection on mount
        if (this.defaultPerformanceBand) {
            this.selectedTargetSet = this.defaultPerformanceBand
        }

        if (this.advancedFilters.length) {
            this.advancedFilters.forEach(filter => {
                // place into string with pipe between values to match overallRecord.filters_applied
                this.advancedDemFilters += '|' + filter.name + ': ' + filter.value
            })
        }

        // In case data arrived pre-set to an unknown band
        // (in which case the watcher for selectedTargetSet would not fire, since it's defaulted)
        if (this.selectedTargetSet) {
            this.checkForUnknownBand(this.selectedTargetSet)
        }

        this.currentTargetSet = this.selectedTargetSet.id
    },

    destroyed() {
        window.removeEventListener('keydown', this.onKeyDown)
    },

    methods: {
        setStackingPrefs() {
            let c = this.uiConfig?.chart
            if (c) {
                let po = this.localConfig.plotOptions

                po.column.stacking = this.stackingType
                po.line.stacking = this.stackingType
            }

            this.ready = true
        },

        disableTooltip() {
            this.$nextTick(() => {
                let title = this.$refs.title
                let disable = true

                if (title.offsetHeight < title.scrollHeight) disable = false
                this.tooltipDisable = disable
            })
        },

        // If backend offers new chart results for a PB that has been
        // created very recently that we don't know about, or for any other
        // reason sets up with such an unknown value, request updated PBs
        checkForUnknownBand(band) {
            if (!this.filteredPerformanceBands.find(pb => pb.id === band?.id || pb.id === band)) {
                this.selectedTargetSet = 0 // if band is not available (ie disabled or...?), show default text
                this.$emit('bandNotFound', band)
            }
        },

        createSLO() {
            let cohortSchoolYearId

            // Some searches, e.g. district search from charting/analytics, may not
            // specific any cohort year; they'll be strictly limited to data year(s)
            if (this.params.cohort_school_year_id?.length) {
                cohortSchoolYearId = this.params.cohort_school_year_id[0]
            } else if (this.params.school_year_id?.length) {
                // If there are multiple data SY requested, this should never be
                // reached because button should be disabled
                if (this.params.school_year_id.length > 1) {
                    this.hideSlo = true
                    return
                }

                cohortSchoolYearId = this.params.school_year_id[0]
            } else if (this.params.school_year_id) {
                cohortSchoolYearId = this.params.school_year_id
            } else {
                // Shouldn't ever reach this, but if no appropriate year data exists, do nothing
                this.hideSlo = true
                return
            }

            if (cohortSchoolYearId) {
                if (this.currentYear.id !== cohortSchoolYearId) this.showSLOCreationYear = true
                else this.hideSlo = false
            }
        },

        cancelSLO() {
            this.showSLOCreationYear = false
            this.hideSlo = true
        },

        onKeyDown(e) {
            if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) this.hideSlo = true
        },

        clickChart(params, found) {
            let me = this
            let clickConfig = this.$_.cloneDeep({...this.localConfig})

            if (!this.hideSlo) {
                let window = me.$dockableWindow({
                    component: 'SloCreationCharting',
                    name: 'Create SLO',
                    attrs: {
                        params: params,
                        overallResults: me.overallRecord,
                        clickRecord: found,
                        targetSet: me.selectedTargetSet,
                        options: clickConfig,
                        categoryId: me.params.category_id
                    },
                    events: {
                        close() {
                            me.$store.commit('global/removeDockedWindow', window)
                        }
                    },
                })

                this.hideSlo = true
            } else {
                let attrs = {
                    params: params,
                    clickRecord: found,
                    record: Object.keys(me.sloDrilldown).length ? me.sloDrilldown : me.overallRecord,
                }

                // Emit for any parent component that might rather handle a drilldown on its own
                this.$emit('chartDrilldown', {name: me.overallRecord.sub_category_name, attrs: attrs})

                if (!this.suppressDefaultDrilldown) {
                    me.$dockableWindow({
                        component: 'chart-drilldown',
                        name: me.overallRecord.sub_category_name,
                        attrs: attrs,
                    })
                }
            }
        },

        exportCSV() {
            // add percentage series to data so it exports
            this.$refs.chartRef.chart.series.forEach((serie, index) => {
                let data = []
                if (serie.type !== 'line' && !serie.symbol) {
                    serie.data.forEach(d => {
                        data.push({y: Math.round(d.percentage), category: d.category})
                    })
                    this.$refs.chartRef.chart.addSeries({
                        name: '% ' + serie.name, data: data, id: 'percentage_' + index
                    }, false)
                }
            })

            let chartData = "data:text/csv;charset=utf8," + this.$refs.chartRef.chart.getCSV()
            let encodedUri = encodeURI(chartData)
            var link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", this.getTitle + ".csv")
            document.body.appendChild(link) // Required for FF
            link.click()

            // remove the new series so the chart doesn't redraw with them
            this.$refs.chartRef.chart.series.forEach((serie, index) => {
                if (this.$refs.chartRef.chart.get('percentage_' + index)) {
                    this.$refs.chartRef.chart.get('percentage_' + index).remove()
                }
            })
        },

        resizeCharts() {
            if (this.$refs.chartRef?.chart) {
                this.$refs.chartRef.chart.reflow()
            }
        },

        print() {
            this.$refs.chartRef.chart.print()
        },

        findTypeTargetSetId(rec, overall, type) {
            let val = null
            if (rec.descriptor_target_set_id) {
                return rec.descriptor_target_set_id
            } else if (overall[type.toUpperCase()]?.length) {
                overall[type.toUpperCase()].forEach((desc, i) => {
                    if (val === null && desc.descriptor_target_set_id) {
                        val = desc.descriptor_target_set_id
                    }
                })
            }
            return val
        },

        viewBy(t) {
            this.stackingType = t

            if (t === 'percent') {
                this.localConfig.yAxis[0].max = 100
            } else {
                this.localConfig.yAxis[0].max = undefined
            }
            this.parseConfig(null, this.overallRecord)
        },

        doLegend() {
            this.showLegend = !this.showLegend
            this.parseConfig(null, this.overallRecord)
        },

        doShowLabels() {
            this.showLabels = !this.showLabels
            this.parseConfig(null, this.overallRecord)
        },

        doChartDetail() {
            this.updatingChart = true
            this.localType = this.localType === 'basic' ? 'detailed' : 'basic'
            this.parseConfig(null, this.overallRecord)
            this.updatingChart = false
        },

        requeryData() {
            let p = this.$_.cloneDeep(this.overallRecord.student_params)
            let t = this.selectedTargetSet.id
            let url = ''
            p.target_set_id = t
            if (p.period && p.period instanceof Array) p.period = p.period.join('+')
            this.localConfig.series = []
            this.isLoading = true
            if (t !== this.currentTargetSet || typeof this.config.search_id === 'undefined') {
                // user changed the performance band target set
                url = 'targetScores.php?action=get&' + this.$objectToParams(p)
                this.currentTargetSet = t
            } else {
                // user Updated Pin and saved new search params
                let obj = {
                    saved_search_id: this.config.search_id.saved_search_id,
                    target_set_id: this.currentTargetSet
                }
                url = 'targetScores.php?action=get&' + this.$objectToParams(obj)
            }
            this.$axios.get(url).then(r => {
                if (r?.data?.categories) {
                    let d = r.data.categories
                    if (d.length) {
                        this.parseConfig(null, d[0].stacked_charts[0])
                    }
                }
            }).finally(() => this.isLoading = false)
        },

        doExpand() {
            let c = {
                config: this.config,
                type: this.localType,
                height: 'fullscreen',
                border: false,
                class: 'ma-10',
                pinnable: this.pinnable,
                projections: this.projections,
                performanceBands: this.performanceBands,
                defaultPerformanceBand: this.selectedTargetSet,
                params: this.baseParams,
                expanded: true,
                collectionChart: this.collectionChart,
                renameFill: this.getTitle,
                isProgramEval: this.localIsProgramEval || this.isProgramEval
            }
            this.$store.commit('global/addDockableWindow', {
                name: this.getTitle,
                component: 'benchmark-chart',
                attrs: c,
                events: {
                    close: () => {
                        // closing expanded chart dockable window
                        this.renameHold = null // resetting var used to prefill the rename dialog
                        this.requeryData()
                    }
                }
            })
        },

        doSort(sortBy, sort) {
            this.$refs.menuBtn.hide()

            if (sort) {
                if (sortBy === 'grade') {
                    this.chartRecord.sort((s1, s2) => {
                        return s1.student_params.grade_id > s2.student_params.grade_id ? 1 : s2.student_params.grade_id > s1.student_params.grade_id ? -1 : 0
                    })
                } else {
                    this.chartRecord.sort((s1, s2) => {
                        return s1[sortBy] > s2[sortBy] ? 1 : s2[sortBy] > s1[sortBy] ? -1 : 0
                    })
                }

                this.chartRecord.forEach((rec, i) => {
                    rec.x = i
                })

                if (this.localType === 'detailed') this.overallRecord.DETAILED = this.chartRecord
                else this.overallRecord.BASIC = this.chartRecord

                this.parseConfig(null, this.overallRecord)
            } else {
                this.chartRecord.sort((s1, s2) => {
                    // Get the totals, based on percent
                    let s1Total = 0
                    let s2Total = 0
                    this.fields.forEach((field) => {
                        s1Total += s1[field] ? s1[field] : 0
                        s2Total += s2[field] ? s2[field] : 0
                    })

                    let s1Pct = s1[sortBy] / s1Total
                    let s2Pct = s2[sortBy] / s2Total

                    return s2Pct - s1Pct
                })

                Highcharts.redraw()
            }
        },

        parseConfig(sortBy, localConfig) {
            let trendline_data = []
            this.overallRecord = localConfig || this.config

            if (!this.overallRecord.noData) {
                // This is used to query to get the list of students
                this.baseParams = this.overallRecord.student_params
                this.sortItems = this.overallRecord.series_sort_fields

                this.hasBasicChartData = (this.overallRecord?.BASIC?.length > 0)
                this.hasDetailedChartData = (this.overallRecord?.DETAILED?.length > 0)

                if (this.localType === 'detailed') {
                    this.chartRecord = [...this.overallRecord.DETAILED]
                    this.fields = this.overallRecord.detailed_fields
                    this.colors = this.overallRecord.detailed_colors

                    // If there is no Detailed data (Performance Benchmarks), switch automatically
                    // to use Benchmark data
                    if (!this.chartRecord.length) {
                        this.localType = 'basic'
                        this.chartRecord = [...this.overallRecord.BASIC]
                        this.fields = this.overallRecord.basic_fields
                        this.colors = this.overallRecord.basic_colors
                    }
                } else {
                    this.chartRecord = [...this.overallRecord.BASIC]
                    this.fields = this.overallRecord.basic_fields
                    this.colors = this.overallRecord.basic_colors

                    if (!this.chartRecord.length) {
                        this.localType = 'detailed'
                        this.chartRecord = [...this.overallRecord.DETAILED]
                        this.fields = this.overallRecord.detailed_fields
                        this.colors = this.overallRecord.detailed_colors
                    }
                }

                this.trendline.fields = this.overallRecord.trendline_fields
                this.trendline.color = this.overallRecord.trendline_colors

                if (this.trendline.fields) {
                    this.trendline.fields.forEach(field => {
                        if (!field.hidden) {
                            trendline_data.push({
                                name: field.name,
                                color: field.color,
                                data: [],
                                min: this.overallRecord.trendline_min,
                                max: this.overallRecord.trendline_max,
                                zIndex: 1
                            })
                        }
                    })
                    trendline_data.forEach(td => {
                        this.chartRecord.forEach(record => {
                            td.data.push(
                                record[td.name]
                            )
                        })
                    })
                    this.trendline.data = trendline_data
                }

                if (sortBy) this.doSort(sortBy)

                this.seriesConfig = this.buildSeries(this.chartRecord, this.fields, this.colors)
                let chartType = 'column'

                // if (this.seriesConfig.categories.length === 1) chartType = 'pie'
                this.localConfig = this.buildChart(this.config, this.seriesConfig, chartType)

                if (this.overallRecord.slo) {
                    // if SLO, don't want goal to be a part of the drilldown
                    this.sloDrilldown = this.$_.cloneDeep(this.overallRecord)
                    this.sloDrilldown.BASIC = this.sloDrilldown.BASIC.filter(chart => {
                        return chart.name !== 'Goal'
                    })
                }
            } else {
                this.title = this.overallRecord.title
            }
        },

        reset() {
            this.localConfig.series = []
            this.localConfig.xAxis = {}
        },

        buildSeries(data, fields, colors) {
            let me = this
            let categories = []
            let series = []

            this.localConfig.series = []
            for (let index in data) {
                let r = data[index]
                // now, iterate through the record and build the series data
                fields.forEach(function (field, fieldIndex) {
                    if (field === "name") return
                    let serie = me.$_.find(series, function (o) {
                        return o.name === field
                    })

                    if (!serie) {
                        serie = {
                            name: field,
                            color: colors[fieldIndex],
                            data: [],
                            custom: {
                                targetDescriptorAliases: r[`${field}-aliases`] || null,
                            },
                        }
                        series.push(serie)
                    }

                    serie.data.push(r[field] ? r[field] : 0)
                })

                categories.push(r.name)
            }

            if (!!me.stackingType) {
                series.reverse()
            }

            if (!this.localIsProgramEval) this.localIsProgramEval = this.isProgramEval || (this.windowOptions && this.windowOptions.name.includes('Program Evaluation'))
            if (!this.projections && !this.localIsProgramEval) {
                this.trendline.data.forEach(data => {
                    series.push({
                        type: this.hideLines && !this.showTrendline ? 'scatter' : 'line',
                        data: data.data.map(d => d === undefined ? d = null : d = d),
                        color: data.color,
                        name: data.name,
                        yAxis: 1,
                        zIndex: 1
                    })
                })
            }

            return {
                categories,
                series
            }
        },

        buildChart(chartOptions, seriesConfig, chartType) {
            let me = this
            let totalStudents = 0
            let studentArr = []
            let studentStr = ' '

            if (seriesConfig.categories.length > 1) {
                seriesConfig.categories.forEach(cat => {
                    studentArr.push({'category': cat, 'data': 0})
                })
            }
            seriesConfig.series.forEach(series => {
                if (_.some(me.fields, _.method('includes', series.name))) {
                    if (series.data.length === 1) totalStudents += series.data[0]
                    else {
                        series.data.forEach((stdnt, i) => {
                            studentArr[i].data += stdnt
                        })
                    }
                }
            })

            this.title = this.renameHold || this.renameFill || me.overallRecord.sub_category_name || this.getTitle
            let trendlineYaxis = this.localIsProgramEval ? {} : {
                title: {
                    text: ''
                },
                gridLineWidth: 0,
                stackLabels: {
                    enabled: false,
                    style: {
                        fontWeight: 'bold'
                    }
                },
                opposite: true,
                labels: {
                    formatter: (params) => {
                        let axisTitle = params?.axis?.axisTitle?.textStr
                        let dataPointTypeId = this.pinParams.data_point_type_id
                        let subCategoryId = this.pinParams.sub_category_id || null
                        return (!axisTitle && dataPointTypeId)
                            ? this.$getAlphaScore(dataPointTypeId, params.value, subCategoryId)
                            : params.value
                    }
                },
                min: 0,
                max: me.overallRecord.trendline_max
            }
            let cfg = {
                chart: {
                    type: chartType,
                    height: me.localHeight,
                    zoomType: 'xy'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: seriesConfig.categories,
                    labels: {
                        useHTML: true,
                        style: {
                            fontSize: '10px',
                            whiteSpace: 'nowrap'
                        }
                    }
                },
                yAxis: [{
                    min: 0,
                    endOnTick: false,
                    max: me.stackingType === 'percent' ? 100 : undefined,
                    title: {
                        text: me.stackingType === 'percent' ? '% of Students' : '# of Students'
                    }
                }, trendlineYaxis],
                legend: this.showLegend ? {
                    enabled: true,
                    align: 'right',
                    verticalAlign: 'bottom',
                    shadow: false
                } : {enabled: false},
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        if (studentArr.length) {
                            studentArr.forEach(stdt => {
                                if (stdt.category === this.x) studentStr = '<br>Total Students: <b>' + stdt.data + '</b>'
                            })
                        } else studentStr = '<br>Total Students: <b>' + totalStudents + '</b>'

                        let aliasesString = ''
                        if (this.series?.options?.custom?.targetDescriptorAliases?.length >= 2) {
                            aliasesString = this.series?.options?.custom?.targetDescriptorAliases.map(s => `&nbsp;&nbsp;&nbsp;&nbsp;${s}`).join('<br/>') + '<br/>'
                        }

                        let dataPointTypeId = me.pinParams?.data_point_type_id
                        if (!this.percentage && !this.total && dataPointTypeId) {
                            return this.series.name + ': <b>' + me.$getAlphaScore(dataPointTypeId, this.y) + '</b>'
                        } else if (this.total && this.y) {
                            let percent = (100 * this.y) / this.total
                            return `
                                    <b>${this.x}</b><br/>
                                    ${this.series.name}: <b>${this.y}</b><br/>
                                    ${aliasesString}
                                    Percent: <b>${percent ? percent.toFixed(1) : '--'}</b>
                                    ${studentStr}
                                `
                        } else if (!this.percentage && !this.total) {
                            return this.series.name + ': <b>' + this.y + '</b>'
                        } else {
                            return `
                                    <b>${this.x}</b><br/>
                                    ${this.series.name}: <b>${this.y}</b><br/>
                                    ${aliasesString}
                                    Percent: <b>${this.percentage ? this.percentage.toFixed(1) : '--'}</b>
                                    ${studentStr}
                                `
                        }
                    }
                },
                plotOptions: {
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                /**
                                 * On click, we need to loop through the raw data to find the record in question.
                                 * This probably could have been stored on the chart series itself, but maybe one day
                                 * when we rewrite the backend.  I'm not coding this anymore ;)
                                 **/
                                click: function (evt) {
                                    let category = this.category
                                    let found = null
                                    let descriptor = this.series ? this.series.name : null

                                    me.chartRecord.forEach(function (rec) {
                                        if (rec.name === category) found = rec
                                    })

                                    if (!found) return
                                    if (found.preventDrilldown) {
                                        if (found.preventDrilldownMessage) me.$snackbars.$emit('new', {
                                            text: found.preventDrilldownMessage,
                                            usage: 'warning'
                                        })
                                        return
                                    }
                                    let descriptorId = found[descriptor + '-TGID']
                                    let params = {
                                        target_descriptor_id: descriptorId,
                                        selected_target_type: me.localType,
                                        ...me.overallRecord.student_params,
                                        ...me.overallRecord.subcat_params,
                                        ...found.subcat_params,
                                        ...found.student_params
                                    }
                                    params.selected_target_type = me.localType
                                    params.target_set_id = me.findTypeTargetSetId(found, me.overallRecord, me.localType)
                                    params.data_point_id = found.student_params.data_point_id

                                    me.clickParams = params
                                    me.clickFound = found
                                    me.clickChart(params, found)
                                }
                            }
                        }
                    },
                    column: {
                        maxPointWidth: 50,
                        stacking: me.stackingType,
                        dataLabels: {
                            allowOverlap: true,
                            enabled: this.showLabels,
                            formatter: function () {
                                if (studentArr.length) {
                                    let stdData = 0
                                    studentArr.forEach(stdt => {
                                        if (this.x === stdt.category) stdData += stdt.data
                                    })
                                    return Math.round(100 * this.y / stdData) + '%';
                                }
                                if (!this.total) {
                                    return Math.round(100 * this.y / totalStudents) + '%';
                                }
                                return Math.round(100 * this.y / this.total) + '%';
                            },
                        }
                    },
                    line: {
                        dataLabels: {
                            allowOverlap: true,
                            enabled: this.showLabels,
                            formatter: function () {
                                if (this.point.index === 0) {
                                    me.previousPoint = this.y
                                    return this.y
                                } else {
                                    let diff = this.y - me.previousPoint
                                    me.previousPoint = this.y
                                    return diff > 0 ? '+' + diff.toFixed(1) : diff.toFixed(1)
                                }
                            }
                        }
                    },
                },
                series: seriesConfig.series
            }

            return cfg
        },

        capitalize(sort) {
            if (sort === 'school_year') return 'School Year'
            else if (sort === 'data_point_name') return 'Window'
            else return _.startCase(_.toLower(sort))
        },

        doUpdate() {
            this.modifyFields = []
            this.selected = {}
            if (this.config?.search_id.saved_search_id) {
                let url = 'savedSearch.php?action=get&property=arguments&saved_search_id=' + this.config.search_id.saved_search_id
                this.$axios.get(url).then(res => {
                    if (res.data.saved_search_arguments.length > 0) {
                        this.searchArguments = res.data.saved_search_arguments
                        res.data.saved_search_arguments.forEach(data => {
                            if (!this.selected[data.param_code]) {
                                this.selected[data.param_code] = []
                            }
                            switch (data.param_code) {
                                case 'GENDER':
                                    this.selected['GENDER'].push({
                                        gender: data.argument_value
                                    })
                                    this.modifyFields.push(data.param_code)
                                    break;
                                case 'SCHOOL_YEAR_ID':
                                case 'COHORT_SCHOOL_YEAR_ID':
                                case 'SCHOOL_ID':
                                case 'GRADE_ID':
                                    this.selected[data.param_code].push({
                                        id: parseInt(data.argument_value)
                                    })
                                    this.modifyFields.push(data.param_code)
                                    break;
                            }
                        })
                        this.showModifyDialog = true
                    }
                })
            }
        },

        modifySavedSearch() {
            let params = []
            this.searchArguments.forEach(p => {
                let name = p.param_code
                if (this.selected[name] && this.selected[name].length > 0) {
                    params.push({
                        saved_search_id: p.saved_search_id,
                        saved_search_param_id: p.param_id,
                        value: this.selected[name].map(value => name === 'GENDER' ? value.gender : value.id).toString()
                    })
                } else {
                    // add old params on top of new params
                    params.push({
                        saved_search_id: p.saved_search_id,
                        saved_search_param_id: p.param_id,
                        value: p.argument_value
                    })
                }
            })
            let url = 'savedSearch.php?action=update&property=arguments&saved_search_id=' + this.config.search_id.saved_search_id

            this.$axios.post(url, {saved_search_arguments: params}).then(res => {
                if (res.data.success) {
                    this.$snackbars.$emit('new', {text: res.data.msg, usage: 'success'})
                    this.requeryData()
                } else {
                    this.$snackbars.$emit('new', {text: res.data.msg, usage: 'warning'})
                }
            })
        },

        doRename() {
            this.newPinName = this.renameHold || this.renameFill || this.getTitle
            this.showRenameDialog = true
        },

        renamePin() {
            if (this.newPinName) {
                let data = this.config.search_id
                let params = {
                    dashboard_id: data.dashboard_id,
                    saved_search_id: data.saved_search_id,
                    rank: data.rank,
                    title: this.newPinName
                }

                let url = 'dashboard.php?action=update&property=saved_search'

                this.$axios.post(url, {saved_searches: [params]}).then(response => {
                    if (response.data.success) {
                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'success'})
                        this.$emit('update:pinTitle', this.newPinName)
                        // if user tries to rename pin a second time in expanded window,
                        // this var will hold the previous rename value so that it shows up by default
                        this.renameHold = this.newPinName
                        if (this.dockedWindowId) {
                            // reload collection behind expanded window
                            // otherwise, title doesn't get updated if renamed in expanded window
                            this.$store.commit('collection/set', {
                                key: 'reloadCollection',
                                value: true
                            })
                            // update title of expanded window
                            this.$store.commit('global/updateDockableWindow', {
                                id: this.dockedWindowId,
                                obj: {
                                    name: this.newPinName
                                }
                            })
                            // Update chart title in expanded window
                            this.buildChart(this.config, this.seriesConfig, 'column')
                        }
                    } else {
                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'warning'})
                    }
                })
            }
        },

        enableTrendline() {
            this.showTrendline = !this.showTrendline
            this.parseConfig()
        },
    }
}
</script>

<style lang="scss" scoped>

.chart-container {
    padding: 16px 16px 16px 16px;
    background: #fff;
}

.border {
    border: 1px solid #E0E1E6;
    border-radius: 5px;
}

.chart-toolbar-title {
    min-height: 100px;
}

.chart-title-bar {
    height: 20px;
    border-radius: 2px;
    padding: 5px;
}

::v-deep .highcharts-container * {
    font-family: "CerebriSans-Regular", Cerebri Sans, "Roboto", sans-serif !important;
}

::v-deep.chart-title-container {
    height: 66px;

    .chart-title {
        color: rgb(126, 132, 148);
        width: calc(100% - 200px);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.performance-band-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

.expand-chart-dialog {
    height: 90vh;
}

.fe-icon {
    position: absolute;
    height: 14px;
    font-family: Font Awesome\ 5 Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #7E8494;
}

.search-icon, .chart-icon {
    width: 14px;
}

.hashtag-icon {
    width: 13px;
}

.percent-icon, .minus-icon, .print-icon {
    width: 11px;
}

::v-deep .cancel-slo .v-btn {
    margin: 1px 8px !important;
}

.show-more-filters-btn {
    align-items: center;
    display: flex;
    color: #006C90;
    font-size: 12px;
    cursor: pointer;
}
</style>
